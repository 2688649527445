




import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import navbar from "@/components/NavBar.vue";
import { NavigationGuardNext, Route } from "vue-router";
import { importJs } from "@/utils/otherCommonPay";

@Component({
  components: {
    Mixin,
    navbar,
  },
})
export default class OtherCommonPay extends Mixins(Mixin) {
  public showBestPay = true;
  public data: any;
  public orderId = this.$route.query.orderId;
  public orderType = this.$route.query.orderType;
  public productSourceEnum = this.$route.query.productSourceEnum;
  public productId = this.$route.query.productId;
  public fromRouteName = ""; // 路由标识 区分来自订单列表/确认订单

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next((vm) => {
      (vm as OtherCommonPay).fromRouteName = from.name || "";
    });
  }
  created(): void {
    let pramas = JSON.parse(String(this.$route.query.data));
    let type = this.$route.query.type as string;
    importJs(type, pramas).then((res: any) => {
      this.data = res;
      console.log("唤起收银台返回数据：", this.data);
      if (this.data.resultCode === "TRADE_SUCCESS") {
        // 支付成功，跳到支付成功页
        console.log("支付成功");
        this.$router.replace({
          path: `/paysuccess/ticket`,
          query: {
            status: "TRADE_SUCCESS",
            orderId: this.orderId,
            orderType: this.orderType,
            productSourceEnum: this.productSourceEnum,
            productId: this.productId,
          },
        });
      } else if (
        // 支付失败/放弃支付/取消支付，跳到我的订单页-
        this.data.resultCode === "TRADE_FAIL" ||
        this.data.resultCode == "USER_ABORT" ||
        this.data.resultCode == "USER_CANCEL" ||
        this.data.resultMsg == "取消支付"
      ) {
        this.$router.replace("/personal-data/order");
      } else if (this.data.resultCode === "TRADE_PAYING") {
        // 交易正在处理中
        console.log("交易正在处理中");
      } else {
        console.log("其他");
        this.$router.replace("/personal-data/order");
      }
      // }
    });
  }
  openBestPay(): void {
    console.log("op");
  }
  getPayStatus(): void {
    //轮询 检测支付状态
    this.$api.paymentApi.bastPayNotify.notify(({ data }) => {
      if (data) {
        console.log("支付成功");
        //去支付成功页面
        this.$router.replace({
          path: `/paysuccess/ticket`,
          query: {
            status: "TRADE_SUCCESS",
            orderId: this.orderId,
            orderType: this.orderType,
            productSourceEnum: this.productSourceEnum,
            productId: this.productId,
          },
        });
      } else {
        console.log("支付失败");

        this.getPayStatus();
      }
    });
  }
}
